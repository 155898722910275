<template>
  <div>
    <v-breadcrumbs v-if="!isEmbeddedInApp" :items="breadcrumbItems"/>

    <v-card>
      <v-card-title class="headline">
        Challenge Setup
      </v-card-title>

      <ConfigChallenge 
        ref="challenge"
        :eventId="this.$route.params.eventId"
        :viewRouteData="{ name: 'profileFriendsChallengeView', params: { eventId: $route.params.eventId }, query: $route.query }" 
        :configRouteData="{ name: 'profileFriendsChallengeConfig', params: { eventId: $route.params.eventId }, query: $route.query }" 
        :org="null"
        />
    </v-card>
    
  </div>      
</template>



<script>
import { mapGetters } from "vuex";
import ConfigChallenge from "@/components/usergeneratedchallenges/ConfigChallenge";
import profileService from "@/services/profileService";
import eventManagerService from "@/services/eventManagerService";
import EventUtil from "@/util/eventUtil";
import { EventBus } from '@/plugins/eventbus.js';
import { DateTime } from 'luxon'
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    ConfigChallenge,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      siteData: siteData,
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
  },
  methods: {
    
    async loadData() {
      if (this.user) {
        //this.$refs.challenge.loadData(this.$route.params.eventId);
      }
    },

  },
  computed: {
    breadcrumbItems() {
      return [
        { text: 'My Profile', exact: true, to: { name: 'profile'} },
        { text: 'Friends', exact: true, to: { name: 'profileFriends' } },
        { text: 'Challenge', disabled: true },
      ];
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
  },
};
</script>
<style lang="scss">
</style>

