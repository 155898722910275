<template>
  <div>
      <v-alert v-if="error || !valid" type="error">{{error || 'There is one or more validation issues. Please check the form fields and correct any errors.' }}</v-alert>

      <v-expansion-panels v-if="event" v-model="wizardStep" accordion tile>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-icon color="gray" class="shrink mr-4">fadl fal fa-info-circle</v-icon>
            <span>Step 1: <strong class="ml-1">Setup</strong></span>
            <v-spacer/>
            <span v-if="stepInfoValid" class="shrink mr-4"><v-icon color="green">fa-check-circle</v-icon></span>
            <span v-if="!stepInfoValid" class="shrink mr-4"><v-icon color="red">fa-times-circle</v-icon></span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="ml-8">Please enter the generic details of your event.</p>
            <v-form ref="form" v-model="valid" lazy-validation>
              
              <v-text-field 
                  v-model="event.name" 
                  :rules="nameRules" 
                  label="Event name (required)" 
                  hint="The name of your challenge."
                  prepend-icon="fa-pencil-alt"
                  required
                  />

              <v-radio-group v-model="event.unit" row class="mt-4" persistent-hint hint="Distance unit for this event. This overwrites user preferences." prepend-icon="fa-ruler">
                <v-radio label="Metric (kilometers)" value="METRIC"></v-radio>
                <v-radio label="Imperial (miles)" value="IMPERIAL"></v-radio>
              </v-radio-group>

              <p class="mt-8 ml-8">Select the event window. Only activities between start and finish dates may count for this event.</p>
              <v-row class="">
                <v-col cols="12" sm="6" class="my-0 py-0">
                  <DateWithTimeZonePicker v-model="event.from" :timeZone="event.timezone" label="Start date (included)" @input="fromDateChanged"/>
                </v-col>
                <v-col cols="12" sm="6" class="my-0 py-0">
                  <DateWithTimeZonePicker v-model="event.till" :minDate="event.from" :timeZone="event.timezone" label="Finish date (included)" />
                </v-col>
              </v-row>

            </v-form>
            <div class="d-flex grey lighten-3 pa-4 mb-n4 mx-n6 mt-8">
              <v-spacer />
              <v-btn class="" :disabled="!stepInfoValid" color="primary" :loading="$store.getters.isLoading" @click="next">Next Step</v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-icon color="gray" class="shrink mr-4">fadl fal fa-trophy</v-icon>
            <span>Step 2: <strong class="ml-1">Challenge Goal</strong></span>
            <v-spacer/>
            <span v-if="stepRacesValid" class="shrink mr-4"><v-icon color="green">fa-check-circle</v-icon></span>
            <span v-if="!stepRacesValid" class="shrink mr-4"><v-icon color="red">fa-times-circle</v-icon></span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>

              <v-row class="mb-4">
                <v-col
                  v-for="(item, idx) in event.races"
                  :key="item.id"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                >
                  <v-card :color="$helpers.getGradientColor(idx)">
                    <v-card-title class="text--bold subtitle">
                      {{item.name}}
                    </v-card-title>
                    <v-divider/>
                    <v-card-text>
                      <div>Type: {{ $t('events.rules.scoring-methods-short.' + item.scoring) }}</div>
                      <div>Goal: {{ $helpers.getGoalDistanceForDisplay($options, item, item.dist, event) }}</div>
                      <div>Sports:             
                      <span v-if="!item.activity_types || item.activity_types.length==0">All</span>
                      <span v-else v-for="(type, idx) in item.activity_types" :key="idx">
                        <v-icon :title="$helpers.getActivityTypeLabel(type)">{{ $helpers.getActivityIcon(type) }}</v-icon>
                        <span v-if="idx != item.activity_types.length-1" class="mx-1">{{ $t('shared.or') }}</span>
                      </span>
                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn v-if="item.type !== 'EXTERNAL'" text @click="$refs.raceDialog.editRace(item)">
                        <v-icon small class="mr-2">fadl fa fa-pencil-alt</v-icon> Edit
                      </v-btn>
                      <v-spacer/>
                      <v-btn text @click="deleteRace(item)">
                        <v-icon small class="mr-2">fadl fa fa-trash</v-icon> Delete
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>

            <div v-if="!event.races || !event.races.length || showAddPanel">
              <p>Select the type of challenge:</p>
              <BigButton icon="fa-flag-checkered" title="Race" text="Set the distance and select a sport and find out who can set the fastest time." @click="$refs.raceDialog.addRaceLeaderboard()" />
              <v-divider/>
              <BigButton icon="fa-ruler" title="Distance Challenge" text="Who can complete a certain distance as first." @click="$refs.raceDialog.addDistanceLeaderboard()" />
              <v-divider/>
              <BigButton icon="fa-hourglass-half" title="Time Challenge" text="Set a total active time goal and find out who can reach this goal as first." @click="$refs.raceDialog.addDurationLeaderboard()" />
              <v-divider/>
              <BigButton icon="fa-shoe-prints" title="Step Challenge" text="Who can reach the step goal first?" @click="$refs.raceDialog.addStepLeaderboard()" />
            </div>
            <div v-else-if="event.races && event.races.length">
              <v-btn color="primary" outlined @click="showAddPanel = true">
                <v-icon class="mr-2">fa-plus-circle</v-icon> Add Another Goal
              </v-btn>
            </div>
            <div class="d-flex grey lighten-3 pa-4 mb-n4 mx-n6 mt-8">
              <v-spacer />
              <v-btn v-if="!stepRacesValid" class="mr-4" outlined color="primary" @click="skip">Skip</v-btn>
              <v-btn v-if="stepRacesValid" class="" :disabled="!stepInfoValid" color="primary" :loading="$store.getters.isLoading" @click="next">Next Step</v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-icon color="gray" class="shrink mr-4">fadl fal fa-fill-drip</v-icon>
            <span>Step 3: <strong class="ml-1">Design</strong> (optional)</span>
            <v-spacer/>
            <span v-if="stepDesignValid" class="shrink mr-4"><v-icon color="green">fa-check-circle</v-icon></span>
            <span v-if="!stepDesignValid" class="shrink mr-4"><v-icon color="grey">fa-times-circle</v-icon></span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>Customize the design of your challenge by selecting a color to identify this challenge.</p>
            <v-text-field 
              v-model="event.color"  
              label="Event Color" 
              @click="showColorPickerMenu=true"
            >
              <template v-slot:append>
                <v-menu v-model="showColorPickerMenu" right :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
                    <div :style="swatchStyle" v-on="on" />
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker v-model="colorForBinding" show-swatches mode="hexa" flat />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </template>                        
            </v-text-field>
            <v-alert v-if="event.color && (!event.color.startsWith('#') || event.color.length !=7)" type="info" dense>
              Please select a valid hex color code.
            </v-alert>
            
            <div class="d-flex grey lighten-3 pa-4 mb-n4 mx-n6 mt-8">
              <v-spacer />
              <v-btn v-if="!stepDesignValid" class="mr-4" outlined color="primary" @click="skip">Skip</v-btn>
              <v-btn v-if="stepDesignValid" class="" :disabled="!stepInfoValid" color="primary" :loading="$store.getters.isLoading" @click="next">Next Step</v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel> 

        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-icon color="gray" class="shrink mr-4">fadl fal fa-paper-plane</v-icon>
            <span>Step 4: <strong class="ml-1">Finalize</strong></span>
            <v-spacer/>
            <span v-if="readyToSubmit" class="shrink mr-4"><v-icon color="green">fa-check-circle</v-icon></span>
            <span v-else class="shrink mr-4"><v-icon color="red">fa-times-circle</v-icon></span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="ml-8">Submit your event details.</p>
            <v-form ref="submitForm" v-model="valid" lazy-validation>
              <v-text-field 
                v-model="event.email" 
                label="Your contact email (not visible to users)" 
                :hint="`Only used by ${tenant.name} to inform you of important updates regarding this challenge or in case we need to contact you. This will not be visible to others.`"
                prepend-icon="fa-envelope"
                :rules="emailRules"
                type="email"
                required
                />

              <v-alert v-if="!readyToSubmit" type="warning">One or more steps are not yet completed. Please complete all steps before you can submit this event information.</v-alert>

              <div class="d-flex grey lighten-3 pa-4 mb-n4 mx-n6 mt-8">
                <v-spacer />
                <v-btn :disabled="!readyToSubmit" class="" color="primary" :loading="$store.getters.isLoading" @click="submit">{{ event.published ? 'Save' : 'Submit'}}</v-btn>
              </div>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>        
      </v-expansion-panels>

    <RaceDialog ref="raceDialog" :event="event" @save="save" :simple="!advancedRaceOptions"/>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import eventManagerService from "@/services/eventManagerService";
import DateWithTimeZonePicker from "@/components/DateWithTimeZonePicker";
import DistanceTextArea from "@/components/DistanceTextArea";
import EventImageUploadForm from "@/components/EventImageUploadForm";
import ActivityUploadForm from "@/components/ActivityUploadForm";
import SupportButton from '@/components/SupportButton.vue'
import BigButton from '@/components/BigButton.vue'
import EventHeader from "@/components/EventHeader.vue";
import RaceDialog from '@/views/eventmanager/_RaceDialog.vue'
import EventUtil from "@/util/eventUtil";
import { EventBus } from '@/plugins/eventbus.js';
import { DateTime } from 'luxon'
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    DateWithTimeZonePicker,
    DistanceTextArea,
    EventImageUploadForm,
    ActivityUploadForm,
    SupportButton,
    BigButton,
    RaceDialog,
    EventHeader,
  },
  props: {
    eventId: String,
    viewRouteData: Object,
    configRouteData: Object,
    org: Object,
  },
  data() {
    return {
      tenant: tenant,
      siteData: siteData,
      wizardStep: 0,
      showAddPanel: false,
      valid: true, 
      savedSnackbar: false,
      error: null,
      event: { unit: null, timezone: null, color: null },
      showColorPickerMenu: false,
      advancedRaceOptions: false,
      nameRules: [
        v => !!v || "Please enter the event name.",
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ],
      optionalEmailRules: [
        v => !v || /.+@.+/.test(v) || 'Enter a valid email address'
      ],
      urlRules: [
        v => !v || v.startsWith('https://') || "Website must start with 'https://' (http is not supported!)",
      ],
      numberRules: [
        v => v > 0 || "Please enter a positive number",
      ],
      showAddRacePanel: true,
      defaultRaces: [
        { name: '5k', scoring: 'RESULT', dist: 5000, activity_types: ['RUNNING', 'WALKING'] },
        { name: '10k', scoring: 'RESULT', dist: 10000, activity_types: ['RUNNING', 'WALKING'] },
        { name: '10 miles', scoring: 'RESULT', dist: 16093, activity_types: ['RUNNING', 'WALKING'] },
        { name: 'Half marathon', scoring: 'RESULT', dist: 21097, activity_types: ['RUNNING', 'WALKING'] },
        { name: 'Marathon', scoring: 'RESULT', dist: 42195, activity_types: ['RUNNING', 'WALKING'] },
      ],
    };
  },
  async mounted() {
    this.tryLoadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.tryLoadData();
    });
  },
  methods: {
    async skip() {
      this.wizardStep++;
    },
    async next() {
      if (await this.save()) {
        this.wizardStep++;
      }
    },
    async back() {
      this.wizardStep--;
    },
    async save() {
      if (!this.$refs.form || this.$refs.form.validate()) {
        this.error = null;
        try {
          this.eventUtil.event = this.event;
          var response;
          if (this.event.id) {
            response = (await eventManagerService.put(this.event)).data;
          }
          else {
            response = (await eventManagerService.create(this.event, /*usercreated*/true)).data;
            this.$router.push(this.configRouteData);
          }
          this.$helpers.toastResponse(this, {status:'OK'}, 'Event Saved Successfully.');
          console.log('Save response', response);
          this.event = response;
          console.log('// saved: ', this.event);
          //await this.getEvent(response.id);
          return true;
        }
        catch (ex) {
          console.log('Save error', ex);
          this.$helpers.toastResponse(this, {status: 'ERROR', msg: 'Error saving event, please check all data for missing or incorrect values.' });
          this.error = ex.response.data.msg;
          // load a fresh copy to ensure we have latest etag and data
          this.loadData();
          return false;
        }
      } 
    },
    async submit() {
      if (!this.$refs.submitForm || this.$refs.submitForm.validate()) {
        this.error = null;
        try {
          this.event.published = true; // mark as published
          var response = (await eventManagerService.put(this.event)).data;
          this.$helpers.toastResponse(this, {status: 'OK'}, 'Event Saved Successfully.');
          this.$router.push(this.viewRouteData);
        }
        catch (ex) {
          this.$helpers.toastResponse(this, {status: 'ERROR', msg: 'Error saving event, please check all data for missing or incorrect values.' });
          // load a fresh copy to ensure we have latest etag and data
          this.loadData();
          //this.error = ex.response.data.msg;
        }
      }
    },

    async fromDateChanged(value) {
      if (this.event.type === 'RESULTS') {
        // sync them to simplify setup
        this.event.till = value;
      }
    },

    async deleteRace (item) {
      const index = this.event.races.indexOf(item);
      confirm('Are you sure you want to DELETE THIS RACE AND ALL RESULTS?') && this.event.races.splice(index, 1) && await this.save();
    },

    async getEvent(id) {
      var response = await eventManagerService.get(id);
      this.event = response.data;
    },

    async tryLoadData() {
      console.log('TryLoadData:', this.eventId, this.event);
      if (this.eventId && (!this.event || this.event.id != this.eventId)) {
        this.loadData();
      }
    },
    async loadData() {
      if (this.user) {
        if (this.eventId) {
          await this.getEvent(this.eventId);
          //if (this.wizardStep == 0) this.wizardStep = 1;
        }
        else {
          var profile = (await profileService.get()).data;
          this.event = {
            unit: profile.unit,
            from: DateTime.now().toISODate(),
            timezone: DateTime.now().zoneName,
            group_id: this.org && this.org.id,
          }
        }
      }
    },

  },
  computed: {
    isCofi() {
      return tenant.id === 'cofi';
    },
    colorForBinding: {
      get() {
        if (!this.event || !this.event.color || this.event.color.length == 0) {
          return "#FFFFFF";
        }
        return this.event.color;
      },
      set(value) {
        console.log('SET', value);
        if (value && value.length > 7) {
          value = value.substring(0, 7).toUpperCase();
          if (value === '#FFFFFF') {
            value = null;
          }
        }
        this.event.color = value;
        this.$set(this.event, 'color', value);
        this.$forceUpdate();
      }
    },
    swatchStyle() {
      const { event, showColorPickerMenu } = this
      return {
        backgroundColor: event.color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        border: 'solid 1px #ccc',
        borderRadius: showColorPickerMenu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      }
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
    eventUtil() {
      return new EventUtil(this, this.event);
    },
    readyToSubmit() {
      return this.stepInfoValid && this.stepRacesValid && this.stepSubmitValid;
    },
    stepInfoValid() {
      return this.event && this.event.name && this.event.from && this.event.till;
    },
    stepRacesValid() {
      return this.event && this.event.races && this.event.races.length;
    },
    stepDesignValid() {
      return true; //this.event && this.event.color;
    },
    stepSubmitValid() {
      return this.event && this.event.email;
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
    eventId(newValue) {
      this.tryLoadData();
    }
  },
};
</script>
<style lang="scss">
</style>

